<template>
  <div class="login-page" :style="backgroundStyle">
    <el-card class="box">
      <img
        :src="logo"
        class="logo"
        :alt="system.name">

      <h1 :style="taglineGradient">{{ system.name }}</h1>
      <template v-if="!activation">
      <h3>{{ system.login_text }}</h3>

      <div class="form">
        <ms-input
          v-model="form.username"
          placeholder="User name/email"
          @enter="login()" />

        <ms-input
          v-model="form.password"
          :options="{ 'show-password': true }"
          placeholder="Password"
          @enter="login()" />

        <div v-if="error" class="error-text">{{ error }}</div>
      </div>

      <el-button
        :style="buttonStyle"
        class="w-full"
        size="large"
        round
        @click="login()">
        Login
      </el-button>

      <div class="create-an-account">
        Forgot password?
        <el-button type="text" class="link" @click="forgotPw">
          Recover here.
        </el-button>
      </div>
      </template>
      <template v-else>
        <h3>Activation</h3>
        <div class="form">
          <ms-input
            v-model="resetForm.password"
            :options="{ 'show-password': true }"
            placeholder="New password"
            @enter="activate()" />

          <ms-input
            v-model="resetForm.confirmPassword"
            :options="{ 'show-password': true }"
            placeholder="Confirm password"
            @enter="activate()" />

          <div class="agree" style="padding: 10px 0 0; text-align: left">
            <span>By activating my account, I agree to the:</span>
            <div style="margin-top: 10px">
              <label>
                <input v-model="resetForm.data1" type="checkbox"> <a @click.prevent="termsModal = true">Terms &amp; Conditions</a>
              </label>
            </div>
            <div style="margin-top: 10px">
              <label>
                <input v-model="resetForm.data2" type="checkbox"> <a @click.prevent="privacyModal = true">Privacy Policy</a>
              </label>
            </div>
          </div>

          <div v-if="error" class="error-text" style="padding-top: 20px">{{ error }}</div>
        </div>

        <el-button
          :style="buttonStyle"
          class="w-full"
          size="large"
          round
          @click="activate()">
          Activate
        </el-button>

      </template>
    </el-card>

     <auth-footer />

    <popup :open.sync="termsModal">
      <div class="privacy-popup scrollable">
        Terms and Conditions
        <terms-popup></terms-popup>
      </div>
    </popup>
    <popup :open.sync="privacyModal" >
      <div class="privacy-popup scrollable">
        Privacy Policy
        <privacy-popup></privacy-popup>
      </div>
    </popup>

  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AuthFooter from '../../components/auth/AuthFooter'
import Popup from '../../components/popup'
import PrivacyPopup from '../PrivacyPopup.vue'
import TermsPopup from '../TermsPopup.vue'

export default {
  components: {
    AuthFooter,
    Popup,
    PrivacyPopup,
    TermsPopup
  },

  data () {
    return {
      form: {
        username: null,
        password: null,
        mode: 'customerApp'
      },
      resetForm: {
        password: null,
        confirmPassword: null,
        data1: false,
        data2: false,
      },
      termsModal: false,
      privacyModal: false,
      version: null,
      error: null,
      year: (new Date()).getFullYear(),
      activation: null,
    }
  },

  computed: {
    taglineGradient () {
      const colorsLeft = this.theme ? this.theme.loginTaglineGradientLeft : '#1E2D4D'
      const colorsRight = this.theme ? this.theme.loginTaglineGradientRight : '#405984'

      return {
        background: `-webkit-linear-gradient(${colorsLeft}, ${colorsRight})`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
      }
    },

    buttonStyle () {
      const colorsLeft = this.theme ? this.theme.loginButtonGradientLeft : '#1E2D4D'
      const colorsRight = this.theme ? this.theme.loginButtonGradientRight : '#405984'
      return {
        background: `linear-gradient(86deg, ${colorsLeft}, ${colorsLeft} 20%, ${colorsRight})`,
        color: this.$colors.white
      }
    },

    backgroundStyle () {
      const colorsLeft = this.theme ? this.theme.loginBackgroundGradientLeft : '#1E2D4D'
      const colorsRight = this.theme ? this.theme.loginBackgroundGradientRight : '#405984'
      return {
        background: `linear-gradient(180deg, ${colorsLeft} 0%, ${colorsRight} 100%)`
      }
    },

    theme () {
      return this.$store.getters['app/theme']
    },

    logo () {
      return (this.system && this.system.logo) ? this.$image('system', this.system.logo) : null
    },
    system () {
      return this.$store.state.app.system
    }
  },

  methods: {
    async login () {
      this.error = null
      this.loading = true
      try {
        await this.$store.dispatch('app/login', this.form)
        await this.$store.dispatch('app/loadData', true)
        this.$router.push({ name: 'Dashboard' })
      } catch (error) {
        if (error.not_active) {
          this.activation = true
        } else {
          this.error = 'Wrong email or password. Please try again.'
        }
      } finally {
        this.loading = false
      }
    },
    async activate() {
      if (!this.resetForm.password)
        return this.error = 'Password is requred'
      if (this.resetForm.password != this.resetForm.confirmPassword)
        return this.error = 'Password confirmation doesn\'t match'
      if (!this.resetForm.data1)
        return this.error = 'You have not agreed to the terms and conditions'
      if (!this.resetForm.data2)
        return this.error = 'You have not agreed to the privacy policy'

      const response = await this.$store.dispatch('app/resetPw', {username: this.form.username, password: this.form.password, newPassword: this.resetForm.password})
      if (response.success) {
        this.form.password = this.resetForm.password
        await this.login()
        this.activation = null
      } else {
        this.$notify.error('There was an error. Please try again.')
      }

    },
    forgotPw() {
      this.$router.push({name: "ForgotPassword"})
    },
  },

  validations () {
    return {
      form: {
        username: { required, email },
        password: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  width: 100%;
  min-height: 100vh;
  padding: 47px 15px 25px 15px;
  box-sizing: border-box;
  text-align: center;

  .box {
    width: 100%;
    left: 15px;
    top: 152px;
    background: $bg_dark;
    border-radius: 18px;
    padding-top: 20px;
  }
  .logo {
    width: 137px;
    height: auto;
  }
  h1 {
    display: block;
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  h3 {
    margin-top: 17px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $text;
  }
  .form {
    margin-top: 72px;
    margin-bottom: 38px;
  }
  .create-an-account {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #616F7B;
    margin-top: 36px;
    margin-bottom: 21px;
    .error-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: $danger;
    }
  }
  .link {
    color: $link;
  }
}
</style>
